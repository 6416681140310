export const quantifierOptions = [
  { value: "non", label: "1 (default)" },
  { value: "?", label: "0 or 1" },
  { value: "*", label: "0 or more" },
  { value: "+", label: "1 or more" },
  {
    value: "custom",
    label: "custom",
  },
]
